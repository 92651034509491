import { useEffect, useState } from 'react';
import { getLanguageFromField } from '../utilities/sharedUtils';
import { TS } from '../utilities/urlConstants';

const urljoin = require('url-join');

const useOnNewActiveLanguageForced = (actualLocale, destination) => {
  const [newActiveLanguage, setNewActiveLanguage] = useState('');

  useEffect(() => {
    if (!newActiveLanguage) return;

    if (actualLocale === newActiveLanguage) return;

    const expectedLanguageObj = getLanguageFromField(
      'locale',
      newActiveLanguage
    );

    // No translations - navigate to homepage
    if (expectedLanguageObj?.languageSiteUrl) {
      window.location.replace(
        urljoin(expectedLanguageObj?.languageSiteUrl, destination, TS)
      );
    }
  }, [newActiveLanguage]);

  return setNewActiveLanguage;
};

export default useOnNewActiveLanguageForced;
