// extracted by mini-css-extract-plugin
export var archiveCount = "AuthorPage-module--archiveCount--0dc17";
export var archiveCountNumber = "AuthorPage-module--archiveCountNumber--ca5f8";
export var archiveCountNumberSmaller = "AuthorPage-module--archiveCountNumberSmaller--84d21";
export var archiveTitle = "AuthorPage-module--archiveTitle--116a8";
export var authorImage = "AuthorPage-module--authorImage--ff07e";
export var authorPageContents = "AuthorPage-module--authorPageContents--7440d";
export var authorPosts = "AuthorPage-module--authorPosts--c89be";
export var authorPostsHeading = "AuthorPage-module--authorPostsHeading--0a2f4";
export var authorSection = "AuthorPage-module--authorSection--df95c";
export var bio = "AuthorPage-module--bio--86aa0";
export var name = "AuthorPage-module--name--01ddf";
export var postsSection = "AuthorPage-module--postsSection--f2fc4";
export var role = "AuthorPage-module--role--3e6db";
export var separator = "AuthorPage-module--separator--1500d";